<template>
  <div class="advanced-table">
    <!-- <TableFilter @reset="clearFilters" @apply="applyFilters" :badgeCount="filterCount">
      <template slot="body" class="body">
        <p class="filter-header">Filter Targets:</p>
        <h5 class="text--input-header">Target Type</h5>
        <div class="types">
          <PassportCheckbox v-model="filters.types.text" label="Text Only" />
          <PassportCheckbox v-model="filters.types.image" label="Image" />
          <PassportCheckbox v-model="filters.types.video" label="Video" />
          <PassportCheckbox v-model="filters.types.quiz" label="Personality Quiz" />
          <PassportCheckbox v-model="filters.types.link" label="Link" />
          <PassportCheckbox v-model="filters.types.trivia" label="Trivia" />
        </div>
      </template>
    </TableFilter> -->
    <PassportModal disableClosingOnMask v-model="showModal">
      <div class="create-group">
        <span class="modal-heading">Edit Group</span>
        <div>
          <span class="input-header"> Group Name* </span>
          <PassportInput v-model="groupName" class="group-title" placeholder="Type your group name (EN)" />
          <PassportInput v-model="groupNameFR" class="group-title" placeholder="Type your group name (FR)" />
          <span
            :class="{
              'input-instructions': !isError,
              'input-instructions__error': isError,
            }"
          >
            Give this stamp group a name.
          </span>
        </div>
        <div class="stamp-uploader">
          <p class="input-header">Stamp Image*</p>
          <PassportImageUploader
            ref="stampImage"
            class="image-upload"
            key="instance-0"
            :value="iconThumbnail"
            :imageWidth="350"
            :imageHeight="200"
            :maxFileSize="10000000"
            :presignedUrlParams="{ type: 'book', item: 'cover' }"
            v-model="stamp"
            @fileRemovedAction="fileRemoved"
          />
          <span
            :class="{
              'input-instructions': !stampError,
              'input-instructions__error': stampError,
            }"
          >
            Upload a default image for this group.
          </span>
        </div>
        <div class="button-container">
          <PassportButton label="save group" class="save-button" @click="updateGroup" />
          <PassportButton variant="text" class="cancel-button" @click="showModal = false" label="Cancel" />
        </div>
      </div>
    </PassportModal>
    <PassportModal disableClosingOnMask v-model="showDeleteModal">
      <div class="section">
        <!-- <div class="icon-container">
          <DeleteIcon class="icon" />
        </div> -->
        <span class="modal-heading">Deletion Error</span>
        <hr class="modal-separator" />
        <div class="choice-text">
          <span>
            You cannot delete a group that already has targets assigned. Please assign the existing targets to a new
            group before deleting this group.
          </span>
        </div>
        <div class="button-container">
          <PassportButton label="okay" class="okay-button" @click="showDeleteModal = false" />
        </div>
      </div>
    </PassportModal>
    <DataTable
      :tableData="filteredGroups"
      :headers="headers"
      :loading="showSkeleton"
      :heading="heading"
      :emptyText="'No groups or targets created yet. Get started by creating a group.'"
      :perPage="10"
      :selectedRows.sync="selectedRows"
      ref="dataTable"
      :defaultSort="{ prop: 'name', order: 'ascending' }"
      searchable
      @row-click="handleRowClick"
    >
      <template v-slot:expand="expand">
        <DataTable
          :tableData="sortData(expand.row.targets)"
          :headers="nestedHeaders"
          :loading="showSkeleton"
          :emptyText="'No targets created yet. Get started by creating a target.'"
          class="inner-table"
        >
          <template v-slot:name="n"
            ><router-link class="target-link" :to="`/edit-target/${n.row.id}`">{{ n.row.name }}</router-link></template
          >
          <template v-slot:type>
            <PassportTableColumn width="200px" label="Target Type">
              <template v-slot="props">
                <span v-if="props.row.targetAsset.assetType === 'trivia'"> Trivia </span>
                <span v-else-if="props.row.targetAsset.assetType === 'text'"> Text </span>
                <span v-else-if="props.row.targetAsset.assetType === 'image'"> Image </span>
                <span v-else-if="props.row.targetAsset.assetType === 'quiz'"> Personality Quiz </span>
                <span v-else-if="props.row.targetAsset.assetType === 'video'"> Video </span>
                <span v-else> Link </span>
              </template>
            </PassportTableColumn>
          </template>
          <template v-slot:qrCodes>
            <PassportTableColumn width="150px" label="QR Codes">
              <template v-slot="props">
                <div class="action-row"></div>
                <DownloadIcon style="cursor: pointer" @click="downloadQRCode(props.row.id)" class="download" />
              </template>
            </PassportTableColumn>
          </template>
          <template v-slot:status>
            <PassportTableColumn width="150px" label="Status">
              <template v-slot="props">
                <div class="action-row">
                  <PassportSwitch
                    class="switch"
                    @click="toggleTargetStatus(props.row.draft, props.row, expand)"
                    v-bind:checked="!props.row.draft"
                    :true-value="!props.row.draft"
                    :false-value="props.row.draft"
                    :label="props.row.draft ? 'Draft' : 'Published'"
                  />
                </div>
              </template>
            </PassportTableColumn>
          </template>
          <template v-slot:columnsAfter>
            <PassportTableColumn width="250px" label="Actions">
              <template v-slot="props">
                <div class="action-row">
                  <PassportButton
                    class="text--table-action"
                    label="Edit"
                    :to="`/edit-target/${props.row.id}`"
                    variant="text"
                  />
                  <div class="g-divider vertical" />
                  <PassportButton
                    class="text--table-action"
                    label="Duplicate"
                    variant="text"
                    @click="duplicateRow(props.row)"
                  />
                  <div class="g-divider vertical" />
                  <PassportButton
                    class="text--table-action"
                    label="Delete"
                    variant="text danger"
                    @click="deleteTarget(props.row.id)"
                  />
                </div>
              </template>
            </PassportTableColumn>
          </template>
        </DataTable>
      </template>
      <template v-slot:qrCodes>
        <PassportTableColumn width="200px" label="QR Codes">
          <template v-slot="props">
            <div class="action-row">
              <DownloadIcon @click.stop="downloadQRs({ targetGroup: props.row.id })" class="download" />
            </div>
          </template>
        </PassportTableColumn>
      </template>
      <template v-slot:status>
        <PassportTableColumn width="150px" label="Status">
          <template v-slot="props">
            <div class="action-row">
              <PassportSwitch
                class="switch"
                @click="toggleGroupStatus(props.row.draft, props.row)"
                v-bind:checked="!props.row.draft"
                :true-value="!props.row.draft"
                :false-value="props.row.draft"
                :label="props.row.draft ? 'Draft' : 'Published'"
              />
            </div>
          </template>
        </PassportTableColumn>
      </template>
      <template v-slot:columnsAfter>
        <PassportTableColumn width="140px" label="Actions">
          <template v-slot="props">
            <div class="action-row">
              <PassportButton
                class="text--table-action"
                label="Edit"
                @click.stop="editGroup(props.row.id)"
                variant="text"
              />
              <div class="g-divider vertical" />
              <PassportButton
                class="text--table-action"
                label="Delete"
                variant="text danger"
                @click.stop="deleteGroup(props.row.id)"
              />
            </div>
          </template>
        </PassportTableColumn>
      </template>
    </DataTable>
    <ModalDelete
      ref="deleteModal"
      :deleteCount="selectedRows.length"
      :icon="() => import('@/assets/icons/nav_assets_v2.svg')"
      @cancel="selectedRows = []"
    />
  </div>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue';
import PassportSwitch from '@/components/PassportSwitch.vue';
// import PassportCheckbox from '@/components/PassportCheckbox.vue';
import { ApiService } from '@/services/api.service';
import QRCodeStyling from 'qr-code-styling';
import DataTable from '@/components/DataTable.vue';
import PassportTableColumn from '@/components/PassportTableColumn.vue';
import ModalDelete from '@/components/ModalDelete.vue';
// import TableFilter from '@/components/TableFilter.vue';
import DownloadIcon from '@/assets/icons/download_icon.svg';
import PassportModal from '@/components/PassportModal.vue';
import PassportInput from '@/components/PassportInput.vue';
import PassportImageUploader from '@/components/PassportImageUploader.vue';
// import { mapState } from 'vuex';
// import DeleteIcon from '@/assets/icons/trash_icon.svg';
import qrUtils from '@/utils/qr';

export default {
  name: 'TargetTable',
  components: {
    DataTable,
    PassportTableColumn,
    PassportButton,
    ModalDelete,
    // TableFilter,
    // PassportCheckbox,
    PassportSwitch,
    PassportModal,
    PassportInput,
    PassportImageUploader,
    DownloadIcon,
    // DeleteIcon,
  },
  props: { heading: String },
  data() {
    return {
      groups: [],
      filteredGroups: [],
      groupInfo: [],
      stampSwitch: false,
      showSkeleton: true,
      showModal: false,
      showDeleteModal: false,
      groupName: '',
      groupNameFR: '',
      groupId: '',
      iconThumbnail: '',
      iconSrc: '',
      isError: false,
      selectedRows: [],
      filters: {
        types: {
          text: false,
          image: false,
          video: false,
          quiz: false,
          link: false,
          trivia: false,
        },
      },
      options: [
        {
          label: 'Text only',
          type: 'text',
        },
        {
          label: 'Image',
          type: 'image',
        },
        {
          label: 'Video',
          type: 'video',
        },
        {
          label: 'Link',
          type: 'link',
        },
        {
          label: 'Personality Quiz',
          type: 'quiz',
        },
        {
          label: 'Trivia',
          type: 'trivia',
        },
      ],
      headers: {
        name: {
          label: 'Group Name',
          sortable: true,
          searchable: true,
          minWidth: '70%',
        },
        assigned: {
          label: 'Assigned Targets',
          sortable: true,
          searchable: false,
          minWidth: '30%',
        },
        expand: {
          type: 'expand',
          width: '20',
        },
      },
      nestedHeaders: {
        name: {
          label: 'Target Name',
        },
      },
      personalityInfo: [],
      triviaInfo: [],
      stamp: '',
      stampError: false,
    };
  },
  computed: {
    filterCount() {
      let count = 0;
      Object.values(this.filters.types).forEach((type) => {
        if (type) count += 1;
      });
      if (this.filters.selectedGroup) count += 1;
      return count;
    },
  },
  mounted() {
    this.getGroups().finally(() => {
      this.showSkeleton = false;
    });
    this.getPersonality();
    this.getTrivia();
  },
  methods: {
    fileRemoved(file) {
      if (file === 'stamp') {
        this.$refs.stampImage = null;
      }
    },
    ...qrUtils,
    handleRowClick(event) {
      this.$refs.dataTable.expandRow(event);
    },
    sortData(targets) {
      const myArray = [...targets];
      if (myArray.length) {
        // if (myArray[0].name.match(/[0-9]+$/)) {
        //   myArray.sort((a, b) => parseInt(a.name.match(/[0-9]+$/)[0], 10) - parseInt(b.name.match(/[0-9]+$/)[0], 10));
        // } else {
        // }
        myArray.sort((a, b) => a.name.localeCompare(b.name));
      }
      return myArray;
    },
    downloadQRCode(id) {
      this.qrURL = `${process.env.VUE_APP_URL}/target/${id}`;
      const qrCode = new QRCodeStyling({
        width: 300,
        height: 300,
        type: 'png',
        data: `${this.qrURL}`,
        backgroundOptions: {
          color: '#ffffff',
        },
        imageOptions: {
          crossOrigin: 'anonymous',
          margin: 20,
        },
      });
      qrCode.download({ name: `target-${id}`, extension: 'png' });
    },
    async getPersonality() {
      await ApiService.get('/quiz/personality')
        .then((response) => {
          Object.keys(response.data.personality).forEach((key) => {
            this.personalityInfo.push({
              id: response.data.personality[key].id,
              name: response.data.personality[key].name,
            });
          });
          this.$store.commit('setPersonalityInfo', this.personalityInfo);
        })
        .catch((err) => {
          this.$notify({
            group: 'primary',
            type: 'error',
            text: `There was an error getting quizzes: ${err.message}`,
          });
        });
    },
    async getTrivia() {
      await ApiService.get('/quiz/trivia')
        .then((response) => {
          Object.keys(response.data.trivia).forEach((key) => {
            this.triviaInfo.push({
              id: response.data.trivia[key].id,
              name: response.data.trivia[key].name,
            });
          });
          this.$store.commit('setTriviaInfo', this.triviaInfo);
        })
        .catch((err) => {
          this.$notify({
            group: 'primary',
            type: 'error',
            text: `There was an error getting quizzes: ${err.message}`,
          });
        });
    },
    async toggleGroupStatus(value, body) {
      body.draft = !value;
      await ApiService.patch(`/targetGroup/${body.id}`, {
        draft: body.draft,
      });
    },
    async toggleTargetStatus(value, body, expand) {
      const { id } = body;
      body.draft = !value;
      await ApiService.put(`/target/${id}`, {
        name: body.name,
        nameFR: body.nameFR,
        description: body.description,
        descriptionFR: body.descriptionFR,
        hintText: body.hintText || undefined,
        hintTextFR: body.hintTextFR || undefined,
        hintTitle: body.hintTitle || undefined,
        hintTitleFR: body.hintTitle || undefined,
        order: body.order,
        points: body.points,
        iconSrc: body.iconSrc,
        targetGroupId: body.targetGroupId,
        draft: body.draft,
        targetAsset: {
          // ...body.targetAsset,
          assetType: body.targetAsset.assetType || undefined,
          assetSrc: body.targetAsset.assetSrc || undefined,
          assetSrc2: body.targetAsset.assetSrc2 || undefined,
          thumbnailSrc: body.targetAsset.thumbnailSrc || undefined,
          assetDescription: body.targetAsset.assetDescription || undefined,
          assetDescriptionFR: body.targetAsset.assetDescription || undefined,
          associatedId: body.targetAsset.associatedId || undefined,
        },
      });
      const grp = this.groups.find((group) => group.id === expand.row.id);
      grp.targets.find((target) => target.id === id).draft = body.draft;
    },
    editGroup(id) {
      this.groupId = id;
      ApiService.get(`/targetGroup/${id}`).then((response) => {
        this.groupName = response.data.name;
        this.groupNameFR = response.data.nameFR;
        this.iconThumbnail = `${process.env.VUE_APP_ASSET_URL}/${response.data.iconSrc}`;
        this.iconSrc = response.data.iconSrc;
      });
      this.showModal = true;
      setTimeout(() => {
        this.$refs.stampImage.file = 'file';
        this.$refs.stampImage.thumbnailPreview = this.iconThumbnail;
      }, 200);
    },
    async updateGroup() {
      if (!this.$refs.stampImage.hasFile) {
        this.stampError = true;
      } else {
        this.stamp = this.$refs.stampImage.file.extension;
        this.stampError = false;
      }
      if (this.stampError) {
        this.$notify({
          group: 'primary',
          type: 'error',
          text: 'Please fix the highlighted errors and try to save again.',
        });
      } else if (this.groupName === '' || this.groupNameFR === '') {
        this.isError = true;
      } else {
        if (this.stamp === 'jpeg') {
          this.stamp = 'jpg';
        }
        // console.log(this.$refs.stampImage);
        if (this.$refs.stampImage.file !== 'file') {
          try {
            const { data } = await ApiService.post(`/targetGroup/${this.groupId}/asset`, {
              fileFormat: this.stamp,
            });
            this.iconSrc = data.assetUrl;
            await this.$refs.stampImage.postS3(data.presignedUrl);
          } catch (err) {
            console.error(err);
          }
        }
        // console.log(this.iconSrc);
        await ApiService.patch(`/targetGroup/${this.groupId}`, {
          name: this.groupName,
          nameFR: this.groupNameFR,
          iconSrc: this.iconSrc,
        }).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.showModal = false;
            this.groupName = '';
            this.groupNameFR = '';
            this.$notify({
              group: 'primary',
              type: 'success',
              text: 'Your group was successfully added.',
            });
          }
        });
        this.getGroups();
      }
    },
    deleteRow(id) {
      // this.selectedRows = [{ ...id, checked: true }];
      // this.deleteGroup(this.selectedRows);
      this.deleteGroup(id);
    },
    async deleteGroup(rowId) {
      // if (!rows.length) return;
      if (!(await this.$refs.deleteModal.confirm())) return;
      await ApiService.delete(`/targetGroup/${rowId}`)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.showModal = false;
            this.$notify({
              group: 'primary',
              type: 'success',
              text: 'Your group was successfully deleted.',
            });
            this.getGroups();
          }
        })
        .catch((error) => {
          if (error.response.data.error === 'group not empty') {
            // console.log('group not empty');
            this.showDeleteModal = true;
          }
        });
      // rowId.forEach(({ id }) => {
      // const index = this.groups.findIndex((groups) => groups.id === rowId);
      // if (index > -1) this.groups.splice(index, 1);
      // });
    },
    async deleteTarget(rowId) {
      // if (!rows.length) return;
      if (!(await this.$refs.deleteModal.confirm())) return;
      await ApiService.delete(`/target/${rowId}`).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.showModal = false;
          this.$notify({
            group: 'primary',
            type: 'success',
            text: 'Your target was successfully deleted.',
          });
          this.getGroups();
        }
      });
      // rowId.forEach(({ id }) => {
      const index = this.groups.findIndex((groups) => groups.id === rowId);
      if (index > -1) this.groups.splice(index, 1);
      // });
    },
    async duplicateRow(row) {
      await ApiService.post(`/target/${row.id}/duplicate`).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.$notify({
            title: 'Success',
            message: 'Target duplicated successfully',
            type: 'success',
          });
        }
      });
      return row;
    },

    applyFilters() {
      const allFilteredGroups = [];
      const appliedFilter = Object.keys(this.filters.types).filter((key) => this.filters.types[key]);
      Object.keys(appliedFilter).forEach((key) => {
        const filteredGroup = this.groups.filter((group) => {
          const filteredTargets = group.targets.filter((target) => target.targetAsset.assetType === appliedFilter[key]);
          return filteredTargets.length > 0;
        });
        allFilteredGroups.push(filteredGroup);
      });
      if (allFilteredGroups.length > 0) {
        this.filteredGroups = allFilteredGroups.flat();
      } else {
        this.filteredGroups = this.groups;
      }
      // this.filteredGroups = [].concat(...allFilteredGroups);
    },
    clearFilters() {
      Object.keys(this.filters.types).forEach((key) => {
        this.filters.types[key] = false;
      });
      this.filteredGroups = this.groups;
    },
    async getGroups() {
      try {
        const { data } = await ApiService.get('/targetGroup', {
          limit: 10,
          offset: 0,
          includeTargets: true,
          includeDraftGroups: true,
          includeDraftTargets: true,
        });
        this.groups = data.targetGroups;
        Object.keys(this.groups).forEach((key) => {
          this.groups[key].assigned = this.groups[key].targets.length;
          this.groupInfo.push({
            id: this.groups[key].id,
            name: this.groups[key].name,
          });
        });
        this.$store.commit('setGroupInfo', this.groupInfo);
      } catch (err) {
        this.$notify({
          group: 'primary',
          type: 'error',
          text: `There was an error getting groups: ${err.message}`,
        });
      }
      this.filteredGroups = this.groups;
    },
  },
};
</script>

<style lang="scss" scoped>
.advanced-table {
  display: flex;
  flex-direction: column;
  width: 100%;

  .target-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 15px;
    color: #222;
    text-decoration: none;
  }

  ::v-deep .inner-table .el-table__body-wrapper tbody tr.el-table__row div.cell {
    cursor: initial !important;
  }

  ::v-deep .el-table::before {
    height: 0;
  }

  ::v-deep .filter {
    position: absolute;
    z-index: 7;
  }
  ::v-deep .search {
    display: none;
    margin-left: 9em;
  }

  .actions {
    align-self: flex-end;
  }
  .download {
    width: 19px;
    g {
      fill: #222;
    }
  }
  ::v-deep .base-file-upload .select-file {
    align-items: center;
    width: 19em;
    margin-bottom: 1em;
  }
  .stamp-uploader {
    margin-top: 1em;
    margin-right: 0.75em;
  }

  .filter-header {
    font-size: 28px;
  }
  ::v-deep .passport-button.default.primary {
    display: flex;
    align-items: center;
    height: 3.4em;
    margin-right: 10px;
    font-size: 14px;
    text-transform: uppercase;
  }
  ::v-deep .passport-button.default.danger {
    display: flex;
    align-items: center;
    height: 3.4em;
    margin-left: 2em;
    font-size: 14px;
    text-transform: uppercase;
  }
  .filter {
    h5 {
      margin: 0;
      margin-bottom: 1em;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  .types {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px 15px;
    margin-bottom: 40px;
  }
  .passport-checkbox {
    flex-shrink: 0;
    color: black;
    ::v-deep {
      .checkbox {
        width: 20px;
        height: 20px;
      }
      .label {
        margin-right: 1em;
        margin-left: 1em;
        font-size: 18px;
        white-space: nowrap;
      }
    }
  }
  .create-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    padding: 70px;
    // background-image: url(../assets/images/bg_modal.png);
    // background-repeat: no-repeat;
    // background-size: cover;
    background-color: #f8ecd9;
    .input-header {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }
    .input-instructions {
      font-size: 14px;
      &__error {
        font-size: 14px;
        color: $red;
      }
    }
    .group-title {
      margin-top: 1em;
      margin-bottom: 1em;
    }
    .button-container {
      display: flex;
      flex-direction: column;
      margin-top: 2em;
    }
    .save-button,
    .cancel-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 136px;
      margin-top: 1em;
    }
    .save-button {
      font-size: 14px;
      text-transform: uppercase;
      border-radius: 4px;
    }
    .modal-heading {
      display: flex;
      justify-content: center;
      margin-bottom: 1em;
      font-size: 34px;
    }
    .modal-separator {
      width: 200px;
      margin-bottom: 2em;
    }
    .choice-text {
      font-size: 16px;
      text-align: center;
    }
  }
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    padding: 90px;
    // background-image: url(../assets/images/bg_modal.png);
    // background-repeat: no-repeat;
    // background-size: cover;
    background-color: #f8ecd9;
    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      margin-bottom: 40px;
      background: $light-opacity;
      border-radius: 50%;
      svg {
        width: 40px;
        height: auto;
        fill: #fff;
      }
    }
    .modal-heading {
      display: flex;
      justify-content: center;
      margin-bottom: 1em;
      font-size: 34px;
    }
    .modal-separator {
      width: 200px;
      margin-bottom: 2em;
    }
    .okay-button {
      margin-top: 1.5em;
    }
    .choice-text {
      font-size: 16px;
      text-align: center;
    }
  }
}
</style>
